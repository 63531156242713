import "../styles.css";
import { useTranslation } from "react-i18next";
import square4 from "../../../assets/img/4-square-astiguimatsm-ok.png";

export default function Desktop4Square({ head, subhead, state }) {
  const { t } = useTranslation();

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{t("Do you see one quadrant sharper than the others")}</h2>
        {/* <h2>{subhead}</h2> */}
      </div>

      <div className="canvas-wrapper" style={{ alignItems: "flex-start"}}>
        <div style={{ maxWidth: "800px", maxHeight: "780px"}}>
          <img src={square4} alt="" style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
}


