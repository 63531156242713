import "../styles.css";
import { useTranslation } from "react-i18next";

export default function MobileHexagono({ answer }) {
  const { t } = useTranslation();

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <h2>{t("Which image is different")}</h2>
          <div class="number-buttons">
            <button class="number-button" onClick={() => { answer(1); }}>1</button>
            <button class="number-button" onClick={() => { answer(2); }}>2</button>
            <button class="number-button" onClick={() => { answer(3); }}>3</button>
            <button class="number-button" onClick={() => { answer(4); }}>4</button>
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer(5)}>
          {t("Can't Tell")}
        </div>
      </div>
    </div>
  );
}
