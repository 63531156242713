
import {
    Q3_ORIENTATIONS,
    INITVA,
    MYOPIC,
    HYPEROPIC,
    MIXED,
    UNKNOWN,
    CHANGE_INTS,
    TD,
} from './constants';

export function getRandomInt(max) {
    return Math.floor(Math.random() * max) + 1;
}

export function getSize(va, scR, td=TD) {
    return scR * 5 * td * 100 * Math.tan((va / 1200) * Math.PI / 180);
}

export function getSize2(va, ratio) {
  // Detecta propriedades do monitor
  const isRetina = window.devicePixelRatio > 1;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;  
  // Calcula PPI real (assumindo monitor de 24")
  const diagonalPixels = Math.sqrt(Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2));
  const ppi = diagonalPixels / 24;
  const scaleFactor = ppi / 96;
  const scaleRetina = 1 ; //isRetina ? 1.2 : 1;

  const sizes = [
    { px: Math.round((454.7 / 6.3 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/95", snellen3m: "3/47.5", snellen20: "20/317", vaPercent: 6.3, vaMethods: 6.3 },
    { px: Math.round((454.7 / 10 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/60", snellen3m: "3/30", snellen20: "20/200", vaPercent: 10, vaMethods: 10 },
    { px: Math.round((454.7 / 16.7 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/36", snellen3m: "3/18", snellen20: "20/120", vaPercent: 16.7, vaMethods: 16.7 },
    { px: Math.round((454.7 / 25 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/24", snellen3m: "3/12", snellen20: "20/80", vaPercent: 25, vaMethods: 25 },
    { px: Math.round((454.7 / 33.3 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/18", snellen3m: "3/9", snellen20: "20/60", vaPercent: 33.3, vaMethods: 33.3 },
    { px: Math.round((454.7 / 50 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/12", snellen3m: "3/6", snellen20: "20/40", vaPercent: 50, vaMethods: 50 },
    { px: Math.round((454.7 / 66.7 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/9", snellen3m: "3/4.5", snellen20: "20/30", vaPercent: 66.7, vaMethods: 66.7 },
    { px: Math.round((454.7 / 80 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/7.5", snellen3m: "3/3.75", snellen20: "20/25", vaPercent: 80, vaMethods: 80 },
    { px: Math.round((454.7 / 100 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/6", snellen3m: "3/3", snellen20: "20/20", vaPercent: 100, vaMethods: 100 },
    { px: Math.round((454.7 / 120 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/5", snellen3m: "3/2.5", snellen20: "20/17", vaPercent: 120, vaMethods: 120 },
    { px: Math.round((454.7 / 150 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/4", snellen3m: "3/2", snellen20: "20/13", vaPercent: 150, vaMethods: 150 },
    { px: Math.round((454.7 / 200 * 6.3) * scaleFactor * scaleRetina), snellen6m: "6/3", snellen3m: "3/1.5", snellen20: "20/10", vaPercent: 200, vaMethods: 200 }
  ]

    if (!va || !ratio || va < 0) return sizes[0].px;

    if (va >= sizes.length) return sizes[sizes.length-1].px;

    return sizes[va]?.px;
}

export function getNewVA(size) {
  const sizes = [
    6.3,
    10,
    16.7,
    25,
    33.3,
    50,
    66.7,
    80,
    100,
    120,
    150,
    200
  ]
  return sizes[size]|| 100;
}

// Axis coord sums -> angle
export function getAngle(axis_x, axis_y, wrongCount) {
    if (wrongCount == Q3_ORIENTATIONS.length) {
        console.log('no stigma')
        return 0
    }
    axis_x = axis_x/(Q3_ORIENTATIONS.length - wrongCount)
    axis_y = axis_y/(Q3_ORIENTATIONS.length - wrongCount)
    // alert("axis_x = "+ axis_x + " , axis_y = " + axis_y)
    let theta = ((Math.atan2(axis_y, axis_x) * 180 / Math.PI + 360) % 360) / 2
    // alert("Math.round(theta) =" + Math.round(theta))
    return Math.round(theta);
}

// or value -> actual orientation for p5
export function getOrientation(or, axis=-1) {
    let theta
    // default for test 2
    if (axis == -1) {
        theta = (or-1)*90
    // left side of screen test 4
    } else if (axis >= 0 && axis < 90) {
        theta = axis + (or-1)*180
    // right side of screen test 4
    } else if (axis >= 90 && axis < 180) {
        theta = axis + (or)*180
    } else {
        console.log('invalid axis: ' + axis)
    }

    return theta;
}

export function getSight(answer) {
    let sight;
    switch (answer) {
        case MYOPIC:
            sight = 'near';
        break;
        case HYPEROPIC:
            sight = 'far';
        break;
        case MIXED:
            sight = 'mixed';
        break;
        case UNKNOWN:
            sight = 'unknown';
        break; 
        default: 
            console.log('invalid sight answer: ' + answer);
        break;
    }
    return sight;
}

export const handleQuestion = (pageStatus, answer, or, va, runCount, wrongCount, changeInt, axis_x, axis_y) => {

    switch (pageStatus) {
        case 5: 
            var {or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect} = handleQ2(answer, or, va, runCount, wrongCount, changeInt, 4)
        break;

        case 6:
            var {or, va, runCount, wrongCount, axis_x, axis_y, nextTest, answerCorrect} = handleQ3(answer, or, va, runCount, wrongCount, axis_x, axis_y)
        break;

        case 7: // Use q2 but with only quadrant 2 options
            var {or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect} = handleQ2(answer, or, va, runCount, wrongCount, changeInt, 2)
        break;

        default: 
            console.log('invalid page: ' + pageStatus);
        break;
    }

    return { or, va, runCount, wrongCount, changeInt, axis_x, axis_y, nextTest, answerCorrect };
}

const handleQ2 = (answer, or, va, runCount, wrongCount, changeInt, quadrant) => {
    console.log('handleQ2...');
    let nextTest = false;
    let answerCorrect = false;

    if (answer === or && va > 5) {
        console.log('Correct!');
        answerCorrect = true;
        va = va - changeInt;
        if (va < 5) va=5;
    } else {
        console.log('Wrong!');
        // if (va > INITVA) {
        //     console.log("Out of Scope!");
        //     nextTest = true;
        //     wrongCount = CHANGE_INTS.length;
        //     runCount = 2;
        // } else {
        wrongCount = wrongCount + 1
        if (wrongCount == CHANGE_INTS.length) {
            va = va + changeInt
        } else {
            va = va + 1*changeInt // Go up 1 or 2 changeInts
            changeInt = CHANGE_INTS[wrongCount]
        }
        // }
    }

    if (wrongCount == CHANGE_INTS.length) {
        runCount = runCount+1;
        changeInt = CHANGE_INTS[0];
        wrongCount = 0;
        if (runCount == 1) {
            console.log("VA-Left: " + va);
            // let SPE = ((va + 18.542) / -83.5) // PUT IN PRESCRIPTION CREATOR
        } else {
            console.log("VA-Right: " + va);
            runCount = 0;
            or = 0;
            nextTest = true;
        }
    }

    if (nextTest == false) {
        or = getRandomInt(quadrant)
    }

    return { or, va, runCount, wrongCount, changeInt, nextTest, answerCorrect };
};

// or means or_idx and wrongCount means skipped axes
const handleQ3 = (answer, or, va, runCount, wrongCount, axis_x, axis_y) => {
    console.log('handleQ3...');
    let nextTest = false;
    let answerCorrect = false;

    if (answer == 2 && va < 250) {
        va = va + 5;
        answerCorrect = true;
    } else if (answer == 4 && va > 10) {
        va = va - 5;
        answerCorrect = true;
    } else if (answer == 1 || answer == 3) {
        let theta = Q3_ORIENTATIONS[or]
        if (answer == 3) {
            theta = (theta + 90)
        }
        console.log("theta = ", theta)
        theta = theta * (Math.PI/90) // 90 b/c range from 0-180
        // neg cos is pos x axis, cancels out with final tan
        axis_x = axis_x + Math.cos(theta)
        axis_y = axis_y + Math.sin(theta)
        or = or + 1;
        answerCorrect = true;
    } else if (answer == 5) {
        wrongCount = wrongCount + 1;
        or = or + 1;
        //THIS IS A DEBUG SKIP
        //END OF DEBUG SKIP
    }

    if (or == Q3_ORIENTATIONS.length) {
        runCount = runCount+1;
        if (runCount == 1) {
            console.log("axis_x-Left: " + axis_x);
            console.log("axis_y-Left: " + axis_y);
            or = 0;
        } else {
            console.log("axis_x-Right: " + axis_x);
            console.log("axis_y-Right: " + axis_y);
            or = getRandomInt(2) //for q4
            runCount = 0;
            nextTest = true;
        }
    }

    return {or, va, runCount, wrongCount, axis_x, axis_y, nextTest, answerCorrect};
}
