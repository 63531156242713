import "../styles.css";
import { useTranslation } from "react-i18next";
import removedGlasses from "../../../assets/img/removed-glasses.jpg";
import MobileMessage from "./MobileMessage";

export default function MobileRemoveGlass({ answer }) {
  const { t } = useTranslation();

  return (
    <MobileMessage
      image={removedGlasses}
      head={t("Remove your glasses")}
      answer={answer}
    />
  );
}
