import { useEffect } from "react";
import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";
import waitSmartphone from "../../../assets/img/wait-smartphone.jpg";

export default function DesktopFollowInstructions({ answer, test, state }) {
  const { t } = useTranslation();

  useEffect(() => {
  }, [test]);

  let sound = "";

  if (test.workflow?.toLowerCase().includes("coverleft") ) {
    sound = "cover-left";
  } else if (test.workflow?.toLowerCase().includes("coverright") ) {
    sound = "cover-right";
  } else if (test.workflow?.toLowerCase().includes("relax") ) {
    sound = "relax-eyes";
  } else {
    sound = test.workflow;
  }

  return (
    <TestWaitScreen
      sound={sound}
      image={waitSmartphone}
      head={t("Follow the instructions on your smartphone")}
    />
  );
}
