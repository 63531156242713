export default function Shape ({ size, isTriangle, isHexagon }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      className="transition-all duration-300"
    >
      {isTriangle ? (
        <path
          d="M50 10 L90 90 L10 90 Z"
          fill="none"
          stroke="black"
          strokeWidth="8"
        />
      ) : (isHexagon ? (
        <path
          d="M50 5 L90 27.5 L90 72.5 L50 95 L10 72.5 L10 27.5 Z"
          fill="none"
          stroke="black"
          strokeWidth="8"
        />
      ) 
      : (
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="black"
          strokeWidth="8"
        />
      ))}
    </svg>
  );
};
