import "../styles.css";
import { useTranslation } from "react-i18next";
import AngleSlider from "../../AngleSlider";

export default function MobileCorner({ answer, state }) {
  const { t } = useTranslation();
  const { angle } = state;
  const handleTap = ( event ) => {
    console.log(event);
    answer(event);
  }

  return (
    <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h2>
            {t("Use the slider to point the arrow to the line that you see sharpest")}
          </h2>
          <AngleSlider answer={handleTap} angle={angle} />
          <div className="nav-buttons" style={{ marginTop: "50px" }}>
            <div className="nav-button" onClick={() => answer("confirm")}>
              {t("Confirm Angule")}
            </div>
            <div className="nav-button" onClick={() => answer("no")}>
              {t("No Diference")}
            </div>
          </div>
        </div>
      </div>
    
  );
}
