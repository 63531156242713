import "../styles.css";
import { useTranslation } from "react-i18next";
import removedGlasses from "../../../assets/img/close-eyes.jpg";
import MobileMessage from "./MobileMessage";

export default function MobileRelaxEyes({ answer }) {
  const { t } = useTranslation();

  return (
    <MobileMessage
      image={removedGlasses}
      head={t("let your hands rest and relax your eyes")}
      answer={answer}
    />
  );
}
