import "../styles.css";
import { useTranslation } from "react-i18next";
import DesktopHexagono from "./DesktopHexagono";

export default function DesktopHexagonoRight({ test, state }) {
  const { t } = useTranslation();

  return (
    <DesktopHexagono
      head={t("Which image is different")}
      test={test}
      state={state}
    />
  );
}
