import "../styles.css";
import { useTranslation } from "react-i18next";
import corner180 from "../../../assets/img/corner180.jpg";

export default function DesktopCorner({ head, subhead, state }) {
  const { angle } = state;
  const { t } = useTranslation();

  
  const RotatingArrow = ({ angle }) => {
    let newAngle = angle;

    const arrowStyle = {
      transform: `translate(-50%, -50%) rotate(${newAngle}deg)`,
      position: 'absolute',
    };

    return (
      <div className="arrow" id="arrow" style={arrowStyle}></div>
    );
  }

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{t("")}</h2>
        {/* <h2>{subhead}</h2> */}
      </div>

      <div className="canvas-wrapper" style={{ alignItems: "flex-start"}}>
        <div className="cont-arrow">
          <img src={corner180} alt="" style={{ width: "1086" }} />
          <div class="arrow-container">
            <RotatingArrow angle={angle} />
        </div>
        </div>
      </div>
    </div>
  );
}


