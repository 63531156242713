import "../styles.css";
import { useTranslation } from "react-i18next";
import MobileCoverEye from "./MobileCoverEye";
import cartoonCoverLeftEye from "../../../assets/img/left-eye.jpg";

export default function MobileCoverLeft({ answer }) {
  const { t } = useTranslation();

  return (
    <MobileCoverEye
      image={cartoonCoverLeftEye}
      head={t("Cover your LEFT eye then press 'Next Stage'")}
      answer={answer}
    />
  );
}
