import Shape from "./Shape";

// Componente principal do teste
export const AstigmatismHexaTriangle = ({ state, size, isTriangle }) => {
  const trianglePosition = (state.or - 1) || 0;

  return (
    <div style={{width: "100%",display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}>
      <TestInterface
          currentSize={size}
          trianglePosition={trianglePosition}
          isTriangle={isTriangle}
        />
    </div>
  );
};

// Componente de interface do teste
const TestInterface = ({ currentSize, trianglePosition, isTriangle }) => {
  return (
    <div style={{width: "80%"}}>
      <ShapesDisplay
        size={currentSize}
        trianglePosition={trianglePosition}
        isTriangle={isTriangle}
      />
    </div>
  );
};

// Componente para exibição das formas
const ShapesDisplay = ({ size, trianglePosition, isTriangle }) => {
  const shapes = Array(4).fill(null).map((_, index) => (
    <Shape
      key={index}
      size={size}
      isHexagon={ index === trianglePosition && !isTriangle }
      isTriangle={ index === trianglePosition && isTriangle }
    />
  ));

  return (
    <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", alignContent: "center", justifyContent: "space-evenly", alignItems: "center"}}>
      {shapes}
    </div>
  );
};