import "../styles.css";
import { getSize2 } from "../../../utils/core";
import { AstigmatismHexaTriangle } from "../../AstigmatismHexaTriangle";



export default function Desktop4Triangle({ head, subhead, state }) {
  const { ratio, currentVaIdx } = state;
  
  // const size = getSize(va, parseFloat(ratio));
  // Diminuir o tamanho dos elementos pois estava muito grande na tela.
  // const currentIdx = (currentVaIdx + 2 > 12) ? 12 : currentVaIdx + 2; 
  const size = getSize2(currentVaIdx, ratio);


  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{head}</h2>
        <h2>{subhead}</h2>
      </div>

      <div className="canvas-wrapper">
        <AstigmatismHexaTriangle state={state} size={size} isTriangle={true}/>
      </div>
    </div>
  );
}


