import React, { useState } from 'react';

function AngleSlider({ answer}) {
  const [angle, setAngle] = useState(0);
  const [displayAngle, setDisplayAngle] = useState(0);

  const createValueMapping = () => {
    const mapping = new Map();

    let leftValue = 90;
    for (let i = -90; i < 0; i += 10) {
      mapping.set(i, leftValue);
      if (leftValue < 170) { 
        leftValue += 10;
      }
    }
    
    mapping.set(0, 0);
    
    for (let i = 10; i <= 90; i += 10) {
      mapping.set(i, i);
    }
    
    return mapping;
  };

  const valueMapping = createValueMapping();

  const handleChange = (event) => {
    const sliderValue = Math.round(Number(event.target.value) / 10) * 10;
    setAngle(sliderValue);

    const displayValue = valueMapping.get(sliderValue);
    setDisplayAngle(displayValue);
    
    answer(sliderValue);
  };

  return (
    <div className="col-md-12 mt-4">
      <div className="row">
        <div className="col-md-12">
          <label htmlFor="angleSlider" className="form-label d-flex justify-content-center justify-content-md-center" style={{ fontSize: "32px" }}>
            {displayAngle}°
          </label>
          <input
            type="range"
            className="form-range"
            id="angleSlider"
            min="-90"
            max="90"
            step="10"
            value={angle}
            onChange={handleChange}
            onInput={handleChange} 
            onMouseMove={(e) => e.buttons === 1 && handleChange(e)} 
          />
        </div>
      </div>
    </div>
  );
}

export default AngleSlider;