import { useTranslation } from "react-i18next";
import "../styles.css";

export default function MobileCoverEye({ image, head, answer }) {
  const { t } = useTranslation();

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <div className="connectionContent" style={{ position: "inherit", justifyContent:"space-evenly", flex: "1 1" }}>
            {image && (
              <div>
                <img src={image} alt="" style={{ width: "70%", marginTop: "20px" }} />
              </div>
            )}
            {head && (
              <div className="">
                <h2 style={{ fontSize: "1.5rem", fontWeight: "normal", color: "#216e75", padding: "0 15px", marginTop: "15px" }}>
                  {head}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer({})}>
          {t("Next Stage")}
        </div>
      </div>
    </div>
  );
}
