import "../styles.css";
import { useTranslation } from "react-i18next";

export default function Mobile4Square({ answer }) {
  const { t } = useTranslation();
  // const size = 80;

  return (
    <div style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <div className="mobile-view">
          <h2>
            {t(
              "Do you see one quadrant sharper than the others"
            )}
          </h2>
          <div className="nav-buttons">
            <div className="nav-button" onClick={() => answer("yes")}>
              {t("Yes")}
            </div>
            <div className="nav-button" onClick={() => answer("no")}>
              {t("No Diference")}
            </div>
          </div>
        </div>
      </div>
    
  );
}
