import "../styles.css";
import { useTranslation } from "react-i18next";
import Desktop4Triangle from "./Desktop4Triangle";

export default function Desktop4TriangleLeft({ test, state }) {
  const { t } = useTranslation();

  return (
    <Desktop4Triangle
      head={t("Which image is different")}
      // subhead={t("Cover your LEFT eye")}
      test={test}
      state={state}
    />
  );
}
