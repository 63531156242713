import "../styles.css";
import { getSize2 } from "../../../utils/core";
import { AstigmatismHexaTriangle } from "../../AstigmatismHexaTriangle";



export default function DesktopHexagono({ head, subhead, state }) {
  const { ratio, currentVaIdx } = state;
  const size = getSize2(currentVaIdx, ratio);

  return (
    <div className="test-instructions-wrapper">
      <div className="test-instructions">
        <h2>{head}</h2>
        <h2>{subhead}</h2>
      </div>

      <div className="canvas-wrapper">
        <AstigmatismHexaTriangle state={state} size={size} isTriangle={false}/>
      </div>
    </div>
  );
}


